import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

import { graphql } from "gatsby"

import { LazyLoadComponent } from "react-lazy-load-image-component"

import { Col, Container, Row } from "react-bootstrap"

// import QuickBaseSolutionsBanner from "../images/new-images/QuickBaseSolutionsBanner.jpg"
import PartnerSwiper from "../components/swiper/swiper-partner.js"

import Banner from "../components/hero/banner-quickbase.js"
import QuickbaseSections from "../components/quick-base-solutions/QuickbaseSections"

class QuickBaseSolutionPage extends React.Component {
  render() {
    return (
      <Layout banner={<Banner />} location={this.props.location}>
        <Seo
          title="Quickbase Solutions | Quandary Consulting Group"
          description="Quickbase is the leading low-code platform that helps you build custom enterprise applications in weeks. without the enterprise price-tag. Learn more."
        />
        <LazyLoadComponent>
          <PartnerSwiper />
        </LazyLoadComponent>
        <LazyLoadComponent>
          <QuickbaseSections props={this.props} />
        </LazyLoadComponent>
      </Layout>
    )
  }
}

export default QuickBaseSolutionPage

export const quickbasePageQuery = graphql`
  query quickbasePageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
